import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  CircularProgress,
  Link,
  Modal,
  IconButton,
} from "@mui/material";
import styled from "@emotion/styled";
import { useInputValues, withInputValues } from "../../contexts/inputContext";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../../constants/constants.js";
import YamlViewer from "../YamlViewer/YamlViewer.jsx";
import CloseIcon from "@mui/icons-material/Close";
import Doc from "../../assets/Doc.png";
import DeleteIcon from "@mui/icons-material/Delete";

const TextFieldStyled = styled(TextField)({
  marginBottom: "20px",
  "& .MuiInputLabel-root": {
    fontFamily: "ubuntu",
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "primary.main",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.main",
    },
  },
  boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  height: "auto",
  width: "100%",
});

const ButtonStyled = styled(Button)({
  borderRadius: "30px",
  padding: "8px 40px",
  backgroundColor: "#1C242C",
  color: "#fff",
  cursor: "pointer",
  height: "45px",
  flexGrow: "0",
  fontFamily: "Ubuntu, sans-serif",
  width: "172px",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "normal",
});

const StyledToggleButton = styled(ToggleButton)({
  height: "28px",
  width: "58px",
  "&.Mui-selected": {
    backgroundColor: "black",
    color: "white",
  },
});

const StyledToggleButtonYaml = styled(ToggleButton)({
  height: "32px",
  width: "112.5px",
  textTransform: 'none',
  fontSize:'14px',
  fontFamily:'Ubuntu',
  fontWeight:'500',
  "&.Mui-selected": {
    backgroundColor: "black",
    color: "white",
  },
});

const FincuroUICodeGeneratorPage = () => {
  const { inputValues, setInputValues } = useInputValues();
  const [applicationName, setApplicationName] = useState("");
  const [developersEmails, setDevelopersEmails] = useState("");
  const [yamlUrl, setYamlUrl] = useState([""]);
  const [yamlUrls, setYamlUrls] = useState([""]);
  const [yamlResponse, setYamlResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toggleValue, setToggleValue] = useState("YES");
  const [yamlType, setYamlType] = useState("Single");
  const [showYamlError, setShowYamlError] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { applicationType } = location.state || {};

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMultipleEmails = (value) => {
    const emails = value.split(/[;,]/);
    setIsEmailValid(emails.every(validateEmail));
  };

  const [validatedYamlUrls, setValidatedYamlUrls] = useState([]);

  const validateYaml = async () => {
    try {
      const urlsToValidate = yamlType === "Multi" ? yamlUrls : [yamlUrl];
  
      const allValidations = urlsToValidate.map(async (url) => {
        if (!url || validatedYamlUrls.includes(url)) return true;
  
        const response = await fetch(
          "https://scaffolding-api.fincuro.in/api/validateYaml",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              yamlUrl: url, // Validate each URL
            }),
          }
        );
        const data = await response.json();
        const isValid = data.status === "success";
  
        if (isValid) {
          // Add the successfully validated URL to the validatedYamlUrls list
          setValidatedYamlUrls((prev) => [...prev, url]);
        }
  
        return isValid;
      });
  
      const validationResults = await Promise.all(allValidations);
  
      if (validationResults.every((result) => result)) {
        setShowYamlError(false);
        setDisableButton(false);
      } else {
        setShowYamlError(true);
        setDisableButton(true);
      }
    } catch (error) {
      console.error("An error occurred during validation:", error);
      setShowYamlError(true);
      setDisableButton(true);
      throw error;
    }
  };
  

  const createUiGenConfigFile = async () => {
    try {
      const apiUrl = toggleValue === "YES"
        ? (yamlType === "Multi" ? `${BASE_URL}/compositeJson` : `${BASE_URL}/createUiGenConfigFile`)
        : '';
      const requestBody=toggleValue === "YES"?(yamlType === "Multi" ? yamlUrls : [yamlUrl]):'';
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ yamlUrls: requestBody }),
      };
  
      const response = await fetch(apiUrl, requestOptions);
  
      if (response.ok) {
        const data = await response.json();
        setYamlResponse(data.data);
        setInputValues({
          orgName: inputValues.orgName,
          ...data?.data,
          isYamlResponse: true,
        });
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  };
  
  const onClickHandler = async () => {
    try {
      await createUiGenConfigFile();
      navigate("/form");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleBackStep = () => {
    navigate("/");
  };

  useEffect(() => {
    if (toggleValue == "NO") {
      setShowYamlError(false);
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  }, [toggleValue]);

  const handleAddYamlUrl = () => {
    setYamlUrls([...yamlUrls, ""]);
  };

  const handleYamlUrlChange = (index, value) => {
    const newYamlUrls = [...yamlUrls];
    newYamlUrls[index] = value;
    setYamlUrls(newYamlUrls);
  };

  const handleDeleteYamlUrl = (index) => {
    const newYamlUrls = yamlUrls.filter((_, i) => i !== index);
    setYamlUrls(newYamlUrls);
  };

  const renderYamlInputFields = () => {
    if (yamlType === "Single") {
      return (
        <TextFieldStyled
          label="Enter The path to YAML file"
          name="yamlUrl"
          value={yamlUrl}
          sx={{ marginTop: "1rem" }}
          onChange={(e) => setYamlUrl(e.target.value)}
          onBlur={validateYaml}
          variant="outlined"
        />
      );
    }

    return (
      <>
        {yamlUrls.map((url, index) => (
          <Grid container spacing={1} alignItems="center" key={index}>
            <Grid item xs={11}>
              <TextFieldStyled
                label={`Enter The path to YAML file ${index + 1}`}
                name={`yamlUrl${index}`}
                value={url}
                sx={{ marginTop: "1rem" }}
                onChange={(e) => handleYamlUrlChange(index, e.target.value)}
                onBlur={validateYaml}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteYamlUrl(index)}
                sx={{ marginTop: "1rem" }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="flex-end">
          <ButtonStyled
            variant="contained"
            color="primary"
            size="small"
            sx={{ marginTop: "1rem", textTransform: "none" }}
            onClick={handleAddYamlUrl}
          >
            Add Yaml
          </ButtonStyled>
        </Grid>
      </>
    );
  };

  if (loading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        disableShrink
      />
    );

  return (
    <Box
      className="background-image-div"
      sx={{ padding: "27px 350px 27px 350px", margin: "0" }}
    >
      <Paper
        sx={{
          padding: "25px 40px 25px 40px",
          minWidth: "672px",
          borderRadius: "10px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8} sx={{ marginBottom: "2.5rem" }}>
            <button
              onClick={handleBackStep}
              style={{
                all: "unset",
                marginRight: "6rem",
                padding: "0",
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                color: "#000000",
                cursor: "pointer",
                flexGrow: "0",
                fontFamily: "Ubuntu, sans-serif",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              <svg
                width="10"
                height="19"
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17.625L2 9.3125L9 1"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              &nbsp; &nbsp; Back
            </button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography
              sx={{
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: "500",
                fontSize: "22px",
                lineHeight: "25px",
                color: "#1C242C",
                textAlign: "left",
                marginLeft: "5px",
              }}
              variant="h4"
              component="h1"
              gutterBottom
            >
              Microservice Available?
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <ToggleButtonGroup
              value={toggleValue}
              exclusive
              onChange={(event, newValue) => setToggleValue(newValue)}
            >
              <StyledToggleButton value="YES">YES</StyledToggleButton>
              <StyledToggleButton value="NO">NO</StyledToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        {toggleValue === "YES" && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ToggleButtonGroup
                value={yamlType}
                exclusive
                onChange={(event, newValue) => setYamlType(newValue)}
                sx={{ marginTop: "1rem" }}
              >
                <StyledToggleButtonYaml value="Single" >
                  Single Yaml
                </StyledToggleButtonYaml>
                <StyledToggleButtonYaml value="Multi" >
                  Multi Yaml
                </StyledToggleButtonYaml>
              </ToggleButtonGroup>
              {renderYamlInputFields()}
            </Grid>
          </Grid>
        )}

        {toggleValue !== "YES" && (
          <TextFieldStyled
            sx={{ marginTop: "1rem" }}
            label="Application Name"
            name="applicationName"
            value={applicationName}
            onChange={(e) => setApplicationName(e.target.value)}
            variant="outlined"
          />
        )}

        {errorMessage && (
          <Typography
            sx={{ fontStyle: "italic", fontSize: "18px" }}
            color="error"
            variant="h6"
          >
            Authoring UI tool is not ready yet coming soon
          </Typography>
        )}

        {applicationType === "mfe application" && (
          <TextFieldStyled
            label="Organization name"
            name="orgName"
            value={inputValues.orgName}
            sx={{ marginTop: "1rem" }}
            onChange={(e) =>
              setInputValues({ ...inputValues, orgName: e.target.value })
            }
            variant="outlined"
          />
        )}

        {!disableButton && (
          <Link
            sx={{
              color: "#1C242C",
              fontSize: "18px",
              textDecorationColor: "black",
              fontFamily: "Ubuntu, sans-serif",
              marginLeft: "10px",
            }}
            component="button"
            onClick={handleOpen}
          >
            View Yaml File
          </Link>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "697px",
              height: "473px",
              borderRadius: "10px",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography
                  sx={{
                    color: "#1C242C",
                    marginBottom: "20px",
                    fontFamily: "Ubuntu, sans-serif",
                    fontWeight: "700",
                    fontSize: "16px",
                    top: "269px",
                    textDecoration: "underline",
                  }}
                >
                  Preview YAML file
                  <img alt="file" src={Doc} className="docImage" />
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon
                  variant="contained"
                  aria-label="close"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleClose()}
                />
              </Grid>
            </Grid>

            <YamlViewer url={yamlUrl} />
          </Box>
        </Modal>

        <TextFieldStyled
          label="Developers, Emails"
          name="developersEmails"
          value={developersEmails}
          sx={{ marginTop: "1rem" }}
          onChange={(e) => setDevelopersEmails(e.target.value)}
          variant="outlined"
          onBlur={() => validateMultipleEmails(developersEmails)}
        />
        {!isEmailValid && (
          <Typography style={{ color: "red", marginBottom: 5, marginLeft: 5 }}>
            Please enter a valid email.
          </Typography>
        )}
        <ButtonStyled
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          type="submit"
          sx={{ marginTop: "1rem", textTransform: "none" }}
          onClick={onClickHandler}
          // disabled={disableButton || !isEmailValid}
        >
          Continue
        </ButtonStyled>
      </Paper>
    </Box>
  );
};

export default FincuroUICodeGeneratorPage;
